.table-body{
    td{
        padding: 12px 20px!important;
        -webkit-text-wrap: nowrap;
        -moz-text-wrap: nowrap;
        text-wrap: nowrap;
    }
}
.end-of-service{
    tr{
        td{
            border-bottom: 1px solid;
            border-color: #000 !important;
            border-top: 0;
            width: 50%;

            &:nth-child(2){
                text-align: center;
            }
        }
    }
    .first,
    .second{
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        p{
            margin: 0;
            color: #000;
        }
    }
    .bottom-table{
        .table-responsive{
            min-height: 100%;
        }
        .tot1{
            padding: 8px 22px;
            background: #000;
            color: #fff;
        }
    }
}