.admin {
  .tabs-div {
    padding-bottom: 5px;
    border-bottom: 1px solid #e8eaeb;
    margin-bottom: 1rem;
    padding-left: 22px;
    padding-right: 22px;

    span {
      padding: 5px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
  }
  .rules{
    .form-check-label{
        margin: 0 1.5rem;
    }
}
}

@media only screen and (max-width: 500px) {
  .admin{
    .card-body{
      display: block!important;

      .input-group{
        width: 100%!important;
      }

      .add-admin{
        margin-top: 12px;
      }
    }
    .table td {
      text-wrap: nowrap;
    }
  }
  .en .add-admin{
    text-align: right;
  }
  .ar .add-admin{
    text-align: left;
  }
  .ar .rules .form-check-label {
    margin: 0 1.5rem;
  }
  .en .rules .form-check-label {
    margin: 0 .5rem;
  }
}