.custom-tabs-div{
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    display: flex;
    gap: 16px;
    p{
        border: 2px solid #dedede;
        padding: 4px 10px;
        cursor: pointer;
        border-radius: 4px;
        margin: 0;
        display: flex;
        align-items: center;  
        gap: 8px;  
    }
    p.activeP{
        background-color: var(--primary);
        color: #fff;
        border: 2px solid var(--primary);
    }
    span{
        height: 6px;
        width: 6px;
        background: #dedede;
        border-radius: 50%;
    }
    p.activeP span{
        background-color: rgb(255, 128, 0);
    }
}