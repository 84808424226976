.add-organization{
    .avatar-preview2{
        background-color: hsl(0, 0%, 90%) !important;
        height: 9rem!important;
        width: 9rem!important;
        position: relative;
        border-radius: 30px;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
            height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
        }
        .upload-img {
            position: absolute !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%) !important;
        }
    }
    .image-preview{
        img{
            // width: 130px;
            height: 130px;
        }
    }
    @media (max-width: 768px) {
        .avatar-preview2 {
            height: 6rem!important;
            width: 6rem!important;
            img{
                width: 75%!important;
            }
        }
    }
    @media (max-width: 500px) {
        .avatar-preview2 {
            height: 6rem!important;
            width: 100%!important;
            img{
                width: 75%!important;
            }
        }
        .active-section{
            flex-direction: column;
            gap: 16px;
            .input-group{
                width: 100%!important;
            }
        }
    }
}