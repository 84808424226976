.dashboard{
    .card{
        position: relative;
        box-shadow:  0 0 3px #dedede7d;
        cursor: pointer;
        // color: #fff;
        .card-body{
            padding: 2.5rem 1.8rem;
        }
        h2,span {
            color: #444;
            z-index: 2;
            position: relative;
        }

        i{
            position: absolute;
            top: 50%;
            color: #666;
            transform: translate(0, -50%);
            font-size: 50px;
            z-index: 2;
        }
    }
}
.en .dashboard i{
    right: 8px;
    left: auto;
}
.ar .dashboard i{
    left: 8px;
    right: auto;
}
.skeleton-loader {
    .loader-header {
        height: 30px;
        width: 60%;
        background-color: #dedede8f;
        margin-bottom: 10px;
    }
    .loader-content {
        height: 15px;
        width: 100%;
        background-color: #dedede8f;
        margin-top: 16px;
    }
}
.wave-container {
    position: absolute;
    width: 100%;
    height: 100%; /* Adjust based on your need */
    overflow: hidden;
  }
  
  .wave-container svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    animation: wave 14s linear infinite;
  }
  
  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(-40%);
    }
  }
  