.holiday .dayText span{
    text-wrap: nowrap!important;
}
.holiday .currentDay {
    border-radius: 3px;
}
.holiday .dayText {
    height: 30px;
    width: 100%;
    padding: 3px;
    line-height: 23px;
}
.holiday .calendarDayOfWeek {
    margin-bottom: .75rem;
    margin-top: .5rem;
}
.holiday .calendarDayOfWeek div {
    font-weight: 500;
    text-align: center;
}