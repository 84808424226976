.req-card{
  display: flex;
  flex-direction: row!important;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  .title-card{
    display: flex;
    align-items: center;
    gap:8px;

    .dot{
      width: 4px;
      height: 4px;
      background-color: red;
      border-radius: 50%;
    }
    p{
      margin: auto 0;
      
      span{
        color: #222;
        font-weight: 500;
      }
      
    }
  }
  
  .buttons-actions{
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 6rem!important;
        img{
            width: 75%!important;
        }
    }
}
@media only screen and (max-width: 500px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 100%!important;
        img{
            width: 75%!important;
        }
    }

    .en .add-department{
      text-align: right;
    }
    .ar .add-department{
      text-align: left;
    }
  }