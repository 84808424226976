.table-body{
    td{
        padding: 12px 20px!important;
        -webkit-text-wrap: nowrap;
        -moz-text-wrap: nowrap;
        text-wrap: nowrap;
    }
}

.tabs-div {
    // padding-bottom: 5px;
    border-bottom: 2px solid #dedede;
    padding-top: .5rem;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;

    p {
      padding: 4px 13px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
      margin: 0;
    }
    p.activeP{
        background-color: #dedede;
        color: #555;
        border-radius: 5px 5px 0 0;
    }
  }
.certificate .tabs-dev{
    display: grid;
    grid-template-columns: auto auto auto;
}
.addProduct{
    svg{
        max-width: 100%;
    }
.avatar-preview2{
    background-color: hsl(0, 0%, 90%) !important;
    height: 9rem!important;
    width: 9rem!important;
    position: relative;
    border-radius: 30px;
    border: 3px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
    img {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
@media (max-width: 768px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 6rem!important;
        img{
            width: 75%!important;
        }
    }
}
@media (max-width: 500px) {
    .avatar-preview2 {
        height: 6rem!important;
        width: 100%!important;
        img{
            width: 75%!important;
        }
    }
}
}
.profile-link{
    color: #000;
    text-decoration: underline;
    font-weight: 700;
}
.profile-link:hover{
    text-decoration: none;
    color: #555;
}