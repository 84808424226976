.table-body{
    td{
        padding: 12px 20px!important;
        -webkit-text-wrap: nowrap;
        -moz-text-wrap: nowrap;
        text-wrap: nowrap;
    }
}
.addExpenses{
    .image-placeholder .avatar-edit input {
        display: block;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    svg{
        max-width: 100%;
    }
.avatar-preview2{
    background-color: hsl(0, 0%, 90%) !important;
    height: 5rem!important;
    width: 5rem!important;
    position: relative;
    border-radius: 30px;
    border: 3px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
    img {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
  
@media (max-width: 768px) {
    .avatar-preview2 {
        height: 4rem!important;
        width: 4rem!important;
        img{
            width: 75%!important;
        }
    }
}
@media (max-width: 500px) {
    .avatar-preview2 {
        height: 3rem!important;
        width: 100%!important;
        img{
            width: 75%!important;
        }
    }
}
}